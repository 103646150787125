.quote {
	margin-top: 100px;
	margin-bottom: 100px;
	background-color: $color-orange-light;
	
	&.quote-side-light-green {
		background-color: $color-green-light;
	}
	
	&.quote-side-light-orange {
		background-color: $color-orange-light;
	}
	
	&.quote-side-green {
		background-color: $color-green;
	}
	
	&.quote-side-orange {
		background-color: $color-orange;
	}
	
	&.quote-side-white {
		background-color: $color-white;
	}
	
	.inner {
		background-color: $color-white;
	}
	
	&.quote-inner-light-green .inner {
		background-color: $color-green-light;
		
		h1,
		h2 {
			color: $color-green;
		}
	}
	
	&.quote-inner-light-orange .inner {
		background-color: $color-orange-light;
		
		h1,
		h2 {
			color: $color-orange;
		}
	}
	
	&.quote-inner-green .inner {
		background-color: $color-green;
		
		p,
		h1,
		h2,
		.cta {
			color: $color-white;
		}
		
		.cta:hover {
			color: $color-orange;
		}
	}
	
	&.quote-inner-orange .inner {
		background-color: $color-orange;
		
		p,
		h1,
		h2 {
			color: $color-white;
		}
	}
	
	&.quote-inner-white .inner {
		background-color: $color-white;
	}
	
	h2 {
		line-height: 1.2;
		margin-bottom: 1em;
	}
	
	h3 {
		margin-bottom: 1.5em;
	}
	
	.quoteImage {
		height: 100%;
		
		img {
			width: 100%;
			height: 100%;
			min-height: 400px;
			max-height: 800px;
			object-fit: cover;
		}
	}
}
