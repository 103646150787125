
img {
	max-width: 100%;
	height: auto;
}

.full-width-image {
	width: 100%;
	
	img {
		width: 100%;
	}
}

hr {
	opacity: 1;
	background-color: $color-green-light;
	height: 2px !important;
}
