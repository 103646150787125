
// Base styles and modules

@import "./vendor/bootstrap.scss";

@import "./base/base.scss";

@import "./modules/header.scss";
@import "./modules/navigation.scss";
@import "./modules/footer.scss";

@import "./modules/locationImage.scss";
@import "./modules/featureGroups.scss";
@import "./modules/quote.scss";
@import "./modules/day.scss";

// Custom styles for this page

.heroPattern {
	@include media-breakpoint-up(xl) {
		margin-top: -162px;
	}
}

.soldRibbon {
	display: inline-block;
	position: absolute;
	top: 10.5rem;
	right: 0;
	background-color: $color-orange;
	color: $color-white;
	font-weight: $fontWeightBold;
	padding: 0 3rem 0 0.9rem;
	font-size: 2rem;
	line-height: 3rem;
	
	span {
		font-weight: $fontWeightNormal;
		font-size: 1.2rem;
		vertical-align: text-bottom;
	}
}
