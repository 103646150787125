@use "sass:math";

.bannerLogo {
	position: absolute;
	z-index: 2;
	width: 100%;
	top: 50px;
	color: $color-white;
	
	a {
		color: $color-white;
		text-decoration: none;
		
		&:hover {
			color: $color-white;
		}
	}
}

.photoBanner {
	width: 100vw;
	height: calc(100vh - 200px);
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.reducedHeader .photoBanner {
	height: 45vh;
}

$dividerHeight: $padding;
$dividerHeightHalf: math.div($dividerHeight, 2);

.photoBannerDivider {
	position: absolute;
	background-color: $color-green;
	width: 33%;
	height: $dividerHeight;
	margin-top: -$dividerHeightHalf;
	right: 0;
}

@include media-breakpoint-down(lg) {
	.patternHeader {
		margin-top: -224px;
	}
}
