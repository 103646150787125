$fontWeightNormal:	300;
$fontWeightMedium:	500;
$fontWeightBold:		700;

@mixin bodyFont {
	font-family: forma-djr-micro, sans-serif;
	font-weight: $fontWeightNormal;
	font-style: normal;
}

@mixin headerFont {
	font-family: forma-djr-micro, sans-serif;
	font-weight: $fontWeightMedium;
	font-style: normal;
}

@mixin headerTitle {
	font-family: forma-djr-micro, sans-serif;
	font-weight: $fontWeightBold;
	font-style: normal;
}

body {
	@include bodyFont;
}

h1, h2, h3, h4, h5, h6 {
	@include headerFont;
}

h1.logo {
	@include headerTitle;
	width: 7em;
	line-height: 1em;
	font-size: 2.8rem;
	text-shadow: 0 0 10px rgba(0,0,0,0.15);
	
	@include media-breakpoint-down(sm) {
		font-size: 2.2rem;
	}
	
	&.inverted {
		text-shadow: 0 0 10px rgba(255,255,255,0.5);
		
		a {
			color: $color-green;
		}
	}
}

main {
	h1 {
		color: $color-green;
		font-size: 2.4rem;
	}
	
	h2 {
		color: $color-green;
		font-size: 2.4rem;
		line-height: 1.1;
	}
	
	h3 {
		font-size: 1.6rem;
		line-height: 1.2;
	}
	
	h4 {
		font-size: 1.25rem;
		line-height: 1.4;
		font-weight: $fontWeightNormal;
	}
	
	h5 {
		font-size: 1.25rem;
		line-height: 1.4;
	}
	
	a {
		color: $color-green;
		text-decoration: underline;
		text-decoration-color: $color-orange;
		text-decoration-thickness: 0.1em;
		text-underline-offset: 0.15em;
		
		&:hover {
			color: $color-orange;
		}
	}
}

body {
	color: $color-black;
}

p {
	color: $color-body;
	line-height: 1.8;
	
	&:last-of-type {
		margin-bottom: 0;
	}
	
	&.focus {
		font-size: 1.3em;
		line-height: 1.6;
	}
}

.focus-block p {
	font-size: 1.3em;
	line-height: 1.4;
}

.muted,
.subtle {
	opacity: 0.25;
}

blockquote {
	margin: 1rem 0;
	font-size: 1.5rem;
	line-height: 1.4;
	color: $color-orange;
	padding-left: 1rem;
	border-left: 3px solid $color-orange;
}

.subhead {
	position: relative;
	padding-top: 2rem;
	font-size: 2.2rem;
	line-height: 1.4;
	font-weight: $fontWeightMedium;
	
	&:before {
		position: absolute;
		content: ' ';
		display: block;
		background: $color-orange;
		width: 20%;
		height: 0.25rem;
		top: 0;
	}
}

.cta {
	font-size: 1.25rem;
	font-weight: $fontWeightBold;
}
