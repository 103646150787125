.locationImage {
	position: relative;
	
	img {
		width: 100%;
	}
	
	
	.anchor {
		display: none;
		
		@include media-breakpoint-up(xl) {
			display: block;
			position: absolute;
			background: $color-white;
			color: $color-green;
			line-height: 1.1;
			cursor: pointer;
			padding: 0 0.5%;
			font-size: 0.9rem;
			top: 75.25%;
			width: 6%;
			height: 10%;
			overflow: visible;
			white-space: nowrap;
			
			&.cbd 		{ left: 4.5%; }
			&.school	{ left: 11.25%; }
			&.bay			{ left: 19%; }
			&.basin		{ left: 32.25%; }
			&.train		{ left: 43.5%; }
			&.village	{ left: 64.5%; }
			&.beach		{
				right: 4.25%;
				text-align: right;
				
				.info {
					right: 0;
				}
				
				.title {
					right: 0;
				}
			}
			
			.info {
				opacity: 0;
				position: absolute;
				bottom: 3rem;
				transition: opacity $transitionSpeed ease-in-out;
				
				.distance {
					margin-bottom: 0.5rem;
				}
				
				.time {
					font-weight: $fontWeightBold;
				}
			}
			
			.title {
				position: absolute;
				bottom: 0.5rem;
				font-weight: $fontWeightBold;
				transition: color $transitionSpeed ease-in-out;
			}
			
			&:hover {
				.info {
					opacity: 1;
				}
				
				.title {
					color: $color-orange;
				}
			}
		}
	}
}
