@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');

.day {

	.dayHead {
		margin-bottom: 2.5em;
	}
	
	.dayBody {
		background-color: $color-orange-light;
		
		&.am {
			margin-bottom: 180px;
		}
		
		&.pm {
			margin-top: 180px;
		}
		
		h3 {
			font-family: 'Kalam', cursive;
		}
		
		p:last-child {
			margin-bottom: 0;
		}
	}
	
	.dayImage {
		margin-top: -$paddingL;
		margin-bottom: -$paddingL;
		
		img {
			width: 100%;
		}
	}

}