.footerNavigation {
	margin-top: $paddingXL;
	padding: $paddingS 0;
	background-color: $color-green;
	
	a {
		display: inline-block;
		color: $color-white;
		font-weight: $fontWeightMedium;
		text-decoration: none;
		line-height: $paddingS;
		
		&:hover {
			color: mix($color-white, $color-green, 66%);
		}
	}
}

.footer {
	margin-top: $paddingXL;
	margin-bottom: $paddingXL;
	padding-top: $paddingXS;
	border-top: 1px solid mix($color-white, $color-green, 90%);
	
	a {
		color: $color-black;
		text-decoration: none;
		
		&:hover {
			color: $color-green;
		}
	}
	
	address {
		margin-bottom: 0;
	}
}

.disclaimer {
	font-size: 0.75rem;
	color: $color-grey;
	
	a {
		color: $color-green;
		text-decoration: none;
		
		&:hover {
			color: $color-orange;
			text-decoration: underline;
		}
	}
}
