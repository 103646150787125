
// Palette

$color-black:					#000;
$color-grey-dark:			#555;
$color-grey: 					#999;
$color-grey-light:		#ccc;
$color-white: 				#FFF;

$color-green:					#185E3C;
$color-green-light:		#F3F4F0;
$color-orange:				#F37938;
$color-orange-light:	#F1D7CD;

// Colour mapping

$color-body: $color-grey-dark;

// Utilities

.color-green {
	color: $color-green;
}

.color-orange {
	color: $color-orange;
}

.background-light-green {
	background: $color-green-light;
}

.background-light-orange {
	background: $color-orange-light;
}

.background-green {
	background: $color-green;
	color: $color-white;
	
	p,
	h1,
	h2,
	h3 {
		color: $color-white;
	}
}

.background-orange {
	background: $color-orange;
	color: $color-white;
	
	p,
	h1,
	h2,
	h3 {
		color: $color-orange-light;
	}
}

.background-white {
	background: $color-white;
}
