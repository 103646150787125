.navigation {
	pointer-events: none;
	opacity: 0;
	position: absolute;
	width: 100%;
	z-index: 2;
	background-color: $color-green;
	transition: opacity $transitionSpeed;
	
	&.open {
		pointer-events: auto;
		opacity: 1;
	}
	
	a {
		display: inline-block;
		color: $color-white;
		font-size: 1.2em;
		font-weight: $fontWeightMedium;
		text-decoration: none;
		line-height: $paddingS;
		
		&:hover {
			color: mix($color-white, $color-green, 66%);
		}
	}
	
	.close {
		float: right;
		color: $color-white;
		font-size: 35px;
		
		&:hover {
			color: mix($color-white, $color-green, 66%);
		}
	}
}

a.navigationOpen {
	display: inline-block;
	padding: 0.75em;
	border-radius: 0.25em;
	background-color: rgba(0, 0, 0, 0.2);
	
	&:hover {
		background-color: rgba(0, 0, 0, 0.5);
	}
}
