
.btn-risland-primary {
	@include button-variant(
		$color-orange,
		$color-orange,
		$white,
		tint-color($color-orange, $btn-hover-bg-tint-amount),
		tint-color($color-orange, $btn-hover-bg-tint-amount),
		$white
	);
}

.btn-risland-secondary {
	@include button-variant(
		$color-green-light,
		$color-green-light,
		$color-green,
		tint-color($color-green, 80),
		tint-color($color-green, 80),
		$color-green,
		$color-green,
		$color-green,
		$white
	);
}
