@use "sass:math";

$paddingXS:	20px;
$paddingS:	30px;
$padding:		40px;
$paddingL:	60px;
$paddingXL:	80px;

$sectionSpacing: 100px;

body {
	position: relative;
}

main section {
	margin-top: $sectionSpacing;
	margin-bottom: $sectionSpacing;
}

@each $name, $width in $grid-breakpoints {
	.offside-r {
		max-width: 100vw;
		overflow: hidden;
		
		@include media-breakpoint-up($name) {
			$containerWidth: map-get($container-max-widths, $name);
			
			@if $containerWidth {
				margin-left: calc((100% - #{$containerWidth}) / 2);
				
				&.contained {
					padding-right: calc((100% - #{$containerWidth}) / 2);
				}
				
				.inner {
					padding-right: calc(100% - #{$containerWidth});
				}
			}
		}
	}
	
	.offside-l {
		max-width: 100vw;
		overflow: hidden;
		
		@include media-breakpoint-up($name) {
			$containerWidth: map-get($container-max-widths, $name);
			
			@if $containerWidth {
				margin-right: calc((100% - #{$containerWidth}) / 2);
				
				&.contained {
					padding-left: calc((100% - #{$containerWidth}) / 2);
				}
				
				.inner {
					padding-left: calc(100% - #{$containerWidth});
				}
			}
		}
	}
}

.offside-r.padded {
	padding-left: math.div($grid-gutter-width, 2);
}

.offside-l.padded {
	padding-right: math.div($grid-gutter-width, 2);
}
