.featureGroups {
	background-color: $color-green-light;
	
	p {
		margin-top: 0.5em;
		margin-bottom: 0;
		padding-top: 0.5em;
		border-top: 1px solid mix($color-white, $color-green, 80%);
		
		&:first-of-type {
			border-top: 0;
		}
	}
	
	img {
		width: $paddingL;
		height: $paddingL;
		margin-bottom: $paddingXS;
	}
}
